
import Vue, { PropOptions } from "vue";

interface Content {
  Text: String;
}

export default Vue.extend({
  data() {
    return {
      isFirstItem: false,
    };
  },
  props: {
    content: {
      type: Object,
      required: true,
    } as PropOptions<Content>,
  },
});
