var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.content.Layout === null || _vm.content.Layout === 'TextAbove')?_c('div',[_c('div',{class:_vm.content.Style && _vm.content.Style.Class ? '' + _vm.content.Style.Class : ''},[_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"10","offset-xl":"1","data-aos":"fade-up"}},[_c('div',{class:_vm.content.Style && _vm.content.Style.ItemClass
                ? 'item ' + _vm.content.Style.ItemClass
                : 'item',domProps:{"innerHTML":_vm._s(_vm.$md.render(_vm.content.Text))}})]),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.content.Image.data !== null && _vm.content.Image.data !== undefined && _vm.content.Image.data.length > 0)?_c('nuxt-img',{staticClass:"img-responsive",attrs:{"src":this.$config.cdnURL + _vm.content.Image.data[0].attributes.url,"format":"webp","loading":"lazy","alt":_vm.content.Text.replace(/(<([^>]+)>)/gi, '')
                .split(/\s+/)
                .slice(0, 5)
                .join(' ')}}):_vm._e()],1)],1)],1)],1)]):(_vm.content.Layout === 'TextBelow')?_c('div',[_c('div',{class:_vm.content.Style && _vm.content.Style.Class ? '' + _vm.content.Style.Class : ''},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.content.Image.data !== null && _vm.content.Image.data !== undefined && _vm.content.Image.data.length > 0)?_c('nuxt-img',{staticClass:"img-responsive",attrs:{"src":this.$config.cdnURL + _vm.content.Image.data[0].attributes.url,"format":"webp","width":"390","height":"390","alt":_vm.content.Text.replace(/(<([^>]+)>)/gi, '')
              .split(/\s+/)
              .slice(0, 5)
              .join(' ')}}):_vm._e()],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{class:_vm.content.Style && _vm.content.Style.ItemClass
              ? 'item ' + _vm.content.Style.ItemClass
              : 'item content',domProps:{"innerHTML":_vm._s(_vm.$md.render(_vm.content.Text))}})])],1)],1)]):(_vm.content.Layout === 'TextLeft')?_c('div',[_c('div',{class:_vm.content.Style && _vm.content.Style.Class
        ? 'text-left' + _vm.content.Style.Class
        : 'text-left'},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"8","xl":"9"}},[_c('div',{class:_vm.content.Style && _vm.content.Style.ItemClass
              ? 'item ' + _vm.content.Style.ItemClass
              : 'item content',domProps:{"innerHTML":_vm._s(_vm.$md.render(_vm.content.Text))}})]),_vm._v(" "),_c('b-col',{staticClass:"image",attrs:{"cols":"8","lg":"4","xl":"3"}},[(_vm.content.Image.data !== null && _vm.content.Image.data !== undefined && _vm.content.Image.data.length > 0)?_c('nuxt-img',{staticClass:"img-responsive",attrs:{"data-aos":"fade-left","data-aos-once":"false","src":this.$config.cdnURL + _vm.content.Image.data[0].attributes.url,"format":"webp","alt":_vm.content.Text.replace(/(<([^>]+)>)/gi, '')
              .split(/\s+/)
              .slice(0, 5)
              .join(' '),"width":"390","height":"370"}}):_vm._e()],1)],1)],1)]):(_vm.content.Layout === 'TextRight')?_c('div',[(
      _vm.content.Style &&
      _vm.content.Style.Class &&
      _vm.content.Style.Class === 'visual-contact'
    )?_c('div',[_c('div',{class:_vm.content.Style && _vm.content.Style.Class
          ? 'text-right' + _vm.content.Style.Class
          : 'text-right'},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[(_vm.content.Image.data !== null && _vm.content.Image.data !== undefined && _vm.content.Image.data.length > 0)?_c('nuxt-img',{staticClass:"img-responsive",attrs:{"src":this.$config.cdnURL + _vm.content.Image.data[0].attributes.url,"format":"webp","alt":_vm.content.Text.replace(/(<([^>]+)>)/gi, '')
                .split(/\s+/)
                .slice(0, 5)
                .join(' '),"width":"390","height":"370"}}):_vm._e()],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"9"}},[_c('div',{class:_vm.content.Style && _vm.content.Style.ItemClass
                ? 'item ' + _vm.content.Style.ItemClass
                : 'item',domProps:{"innerHTML":_vm._s(_vm.$md.render(_vm.content.Text))}})])],1)],1)]):_c('div',[_c('div',{class:_vm.content.Style && _vm.content.Style.Class
          ? '' + _vm.content.Style.Class
          : 'text-right'},[_c('b-row',[_c('b-col',{staticClass:"image",attrs:{"cols":"8","lg":"4","xl":"3"}},[(_vm.content.Image.data !== null && _vm.content.Image.data !== undefined && _vm.content.Image.data.length > 0)?_c('nuxt-img',{staticClass:"img-responsive",attrs:{"src":this.$config.cdnURL + _vm.content.Image.data[0].attributes.url,"data-aos":"fade-right","data-aos-once":"false","format":"webp","alt":_vm.content.Text.replace(/(<([^>]+)>)/gi, '')
                .split(/\s+/)
                .slice(0, 5)
                .join(' ')}}):_vm._e()],1),_vm._v(" "),_c('b-col',{attrs:{"sm":"12","lg":"8","xl":"9"}},[_c('div',{class:_vm.content.Style && _vm.content.Style.ItemClass
                ? 'item ' + _vm.content.Style.ItemClass
                : 'item content',domProps:{"innerHTML":_vm._s(_vm.$md.render(_vm.content.Text))}})])],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }